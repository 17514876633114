function MainLogo(props) {
  return (
    <svg
      width='100%'
      height='100%'
      version='1.1'
      viewBox='0 0 1200 1200'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='#fff'>
        <path d='m901.82 1167.3c0 7.2734 5.4531 14.547 14.547 14.547h140c7.2734 0 14.547-5.4531 14.547-14.547v-50.91h-167.27v50.91z' />
        <path d='m130.91 1167.3c0 7.2734 5.4531 14.547 14.547 14.547h140c7.2734 0 14.547-5.4531 14.547-14.547v-50.91h-169.09z' />
        <path d='m418.18 476.36c-54.547 16.363-98.184 52.727-129.09 98.184h80c10.906-40 27.27-74.547 49.09-98.184z' />
        <path d='m363.64 601.82h-89.09c-12.727 25.453-20 54.547-21.816 85.453h101.82c0-29.086 3.6367-58.18 9.0898-85.453z' />
        <path d='m354.55 714.55h-101.82c1.8164 30.91 9.0898 60 21.816 85.453h89.09c-5.4531-25.453-9.0898-54.547-9.0898-85.453z' />
        <path d='m289.09 827.27c29.094 47.273 74.547 81.816 129.09 100-20-23.637-36.363-58.184-49.09-98.184h-80.004z' />
        <path d='m578.18 574.55c-43.637-145.45-136.36-145.45-180 0z' />
        <path d='m994.55 761.82c29.09 0 54.547-23.637 54.547-54.547 0-29.09-23.637-54.547-54.547-54.547-29.09 0-54.547 23.637-54.547 54.547 0 30.914 23.637 54.547 54.547 54.547zm-43.637-52.727c0-7.2734 5.4531-14.547 14.547-14.547h60c7.2734 0 14.547 5.4531 14.547 14.547 0 7.2734-5.4531 14.547-14.547 14.547h-61.82c-7.2734-1.8203-12.727-7.2734-12.727-14.547z' />
        <path d='m398.18 827.27c20 65.453 52.727 109.09 89.09 109.09 36.363 0 70.91-43.637 89.09-109.09z' />
        <path d='m390.91 800h192.73c5.4531-25.453 9.0898-54.547 10.91-85.453h-212.73c0 30.906 3.6367 60 9.0938 85.453z' />
        <path d='m994.55 549.09c29.09 0 54.547-23.637 54.547-54.547 0-29.09-23.637-54.547-54.547-54.547-29.09 0-54.547 23.637-54.547 54.547 0 29.094 23.637 54.547 54.547 54.547zm-14.547-85.453c0-7.2734 5.4531-14.547 14.547-14.547 7.2734 0 14.547 5.4531 14.547 14.547v60c0 7.2734-5.4531 14.547-14.547 14.547-7.2734 0-14.547-5.4531-14.547-14.547z' />
        <path d='m583.64 601.82h-192.73c-5.4531 25.453-9.0898 54.547-10.91 85.453h212.73c1.8203-29.086-1.8164-58.18-9.0898-85.453z' />
        <path d='m687.27 574.55c-29.09-47.273-74.547-81.816-129.09-98.184 20 23.637 36.363 58.184 49.09 98.184z' />
        <path d='m160 443.64c-23.637 170.91-23.637 343.64 0 514.55 223.64 32.727 430.91 32.727 654.55 0 23.637-170.91 23.637-343.64 0-514.55-221.82-32.727-430.91-32.727-654.55 0zm327.27 520c-147.27 0-263.64-121.82-263.64-263.64 0-141.82 116.36-263.64 263.64-263.64 147.27 0 263.64 120 263.64 263.64s-116.36 263.64-263.64 263.64z' />
        <path d='m1130.9 312.73h-403.64c-7.2734-63.637-61.816-114.55-127.27-114.55-65.453 0-120 50.91-127.27 114.55h-403.64c-23.637 0-43.637 20-43.637 43.637v689.09c0 23.637 20 43.637 43.637 43.637h1061.8c23.637 0 43.637-20 43.637-43.637l0.003906-689.09c0-23.637-20-43.637-43.637-43.637zm-136.36 100c45.453 0 81.816 36.363 81.816 81.816s-36.363 81.816-81.816 81.816-81.816-36.363-81.816-81.816c-0.003907-45.453 36.359-81.816 81.816-81.816zm0 214.55c45.453 0 81.816 36.363 81.816 81.816s-36.363 81.816-81.816 81.816-81.816-36.363-81.816-81.816c-0.003907-45.453 36.359-81.816 81.816-81.816zm-154.55 345.45c0 5.4531-5.4531 10.91-10.91 10.91-232.73 34.547-449.09 36.363-681.82 0-5.4531 0-10.91-5.4531-10.91-10.91-27.273-180-27.273-361.82-1.8164-543.64 0-5.4531 5.4531-10.91 10.91-10.91 232.73-34.547 449.09-34.547 681.82 0 5.4531 0 10.91 5.4531 10.91 10.91 27.27 181.82 27.27 363.64 1.8164 543.64zm252.73 16.363h-196.36c-7.2734 0-14.547-5.4531-14.547-14.547 0-7.2734 5.4531-14.547 14.547-14.547h196.36c7.2734 0 14.547 5.4531 14.547 14.547-1.8203 9.0938-7.2734 14.547-14.547 14.547zm0-63.637h-196.36c-7.2734 0-14.547-5.4531-14.547-14.547 0-7.2734 5.4531-14.547 14.547-14.547h196.36c7.2734 0 14.547 5.4531 14.547 14.547-1.8203 9.0938-7.2734 14.547-14.547 14.547zm0-61.816h-196.36c-7.2734 0-14.547-5.4531-14.547-14.547 0-7.2734 5.4531-14.547 14.547-14.547h196.36c7.2734 0 14.547 5.4531 14.547 14.547-1.8203 7.2734-7.2734 14.547-14.547 14.547z' />
        <path d='m558.18 927.27c54.547-16.363 98.184-52.727 129.09-98.184h-80c-12.727 40-29.09 72.727-49.09 98.184z' />
        <path d='m612.73 601.82c5.4531 27.273 9.0898 54.547 9.0898 85.453h101.82c-1.8164-30.91-9.0898-58.184-21.816-85.453z' />
        <path d='m612.73 800h89.09c12.727-25.453 20-54.547 21.816-85.453h-101.82c0 30.906-3.6328 60-9.0898 85.453z' />
        <path d='m718.18 227.27 100-76.363c-7.2734-5.4531-14.547-10.91-20-20l-98.184 76.363c5.4531 5.4531 12.727 12.727 18.184 20z' />
        <path d='m481.82 225.45c5.4531-7.2734 12.727-12.727 20-20l-98.184-76.363c-5.4531 7.2734-12.727 14.547-20 20z' />
        <path d='m332.73 138.18c32.727 0 60-27.273 60-60 0-32.727-27.273-60-60-60-32.727 0-60 27.27-60 60 0 32.727 27.273 60 60 60z' />
        <path d='m867.27 138.18c32.727 0 60-27.273 60-60 0-32.73-27.273-60-60-60-32.727 0-60 27.273-60 60 0 32.727 27.273 60 60 60z' />
      </g>
    </svg>
  );
}

export default MainLogo;
